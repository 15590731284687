require('@Desktop/css/app/app.scss');
import $ from 'jquery';
import React from 'react';

//redux store
import configureStore from '@App/js/dataStore/configureStore';

import CreateSelectBoxes from '@App/js/app/select_box.js';
import AccountMenu from '@App/js/popups/accountMenu.js';
import {popupHide,popupShow} from '@App/js/popups/popup.js';

const store = configureStore();
let user=store.getState().reducer_user.user;
var userCheckInterval;

$(function(){
	CreateSelectBoxes();
	userCheckInterval=setInterval(function(){
		if(user.isFinished()){
			clearInterval(userCheckInterval);
			appInit();
		}
	},650);
	
	// $("#BannerSignupContainer").html(window.innerWidth);
	// $("#BannerLoginContainer").html("x"+window.devicePixelRatio);
});


function appInit(){
	$('#PopupBG').on("click", popupHide);
	if(user.username!=null){
		$('#BannerAccountContainer').css('display','block');
		 $('#BannerAccountContainer').click(function(){
			popupShow(<AccountMenu username={user.username}/>,'popupAccountMenu');
		});
	}else{
		$('#BannerLoginContainer').css('display','block');
		$('#BannerLoginContainer').children('a').prop('href','/login?rurl='+window.location.pathname);
		$('#BannerSignupContainer').css('display','block');
	}

	//sending linked url
	$.ajax({
		url:'/data/UpdateTraffic',
		method:'POST',
		data:{"prev_url":document.referrer},
		success:function(data){
			if(data<2){
				//tracking five_sec data
				setTimeout(function(){
					$.ajax({
						url:'/data/UpdateTraffic',
						method:'POST'
					})
				},5000);
			}
		}
	})
	

}



//------------------------------------Banner hide animation when scrolling------------------------------------//
//------------------------------------------------------------------------------------------------------------//
var previousScrollHeight=0;
var bannerY=0;
var bannerGoingUp=false;
var hidingInterval;

window.addEventListener('scroll',function(){
	let newScroll=document.documentElement.scrollTop;
	if(previousScrollHeight<newScroll)
		hideBanner('down');
	else if(previousScrollHeight>newScroll)
		hideBanner('up');
	previousScrollHeight=newScroll;
});

function hideBanner(direction='down',time=200){
	let max=60;
	let speed=2;
	let rate=time/(max/speed);
	
	if(max>0)
		max*=-1; 
	
	if((direction==='down'&&!bannerGoingUp&&bannerY<=0) || (direction==='up'&&bannerGoingUp&&bannerY>=max)){
		clearInterval(hidingInterval);
		if(direction==='down'){
			bannerGoingUp=true;
			speed*=-1;
		}else
			bannerGoingUp=false;
		hidingInterval=setInterval(function(){moveBanner(speed,max);},rate);
	}

}

function moveBanner(speed,max){
	let banner = document.getElementById('BannerContainer');
	bannerY+=speed;
	if(bannerY<max)
		bannerY=max;
	if(bannerY>0)
		bannerY=0;
	$(banner).css('top',bannerY);
	if(bannerY<=max||bannerY===0)
		clearInterval(hidingInterval);
}