import $ from 'jquery';
import React from 'react';
import ReactDOM,{render} from 'react-dom';

function popupShow(reactObj,addedClass=''){
	$('#Popup').removeClass();
	render(reactObj,document.getElementById('Popup'));
	$('#Popup').addClass(addedClass);
	$('#Popup').css('display','block');
	$('#PopupBG').css('display','block');
	$('#Popup').attr('shown','true');
}


function popupHide(){
	$('#Popup').css('display','none');
	$('#Popup').removeClass();
	$('#PopupBG').css('display','none');
	$('#Popup').attr('shown','false');
	ReactDOM.unmountComponentAtNode(document.getElementById('Popup'));
	$('#Popup').html('');
}

function popupShowText(text='',addedClass=''){
	popupShow(<div>{text}</div>,addedClass);
}

function popupShowTextLogin(addedClass=''){
	popupShow(<div>
			<div>You need to be signed in to do this</div>
			<br/>
			<a className="popupButton" style={{"width":"100px"}} href={"/login?rurl="+window.location.pathname}>Login</a>
		</div>,addedClass);
}

export {popupHide,popupShow,popupShowText,popupShowTextLogin};
