import User from "../app/user";

const initState = {
	files: [],
	total: 0,
	uploading: false,
}
  
const Reducer = (state=initState,action) => {

	if(action.type==="ADD_FILE"){//need FILE
		state.files[state.total]=action.file;
		state.total=state.files.length;
		//console.log("new store index "+state.total+" : "+action.file.name);


	}else if(action.type==="REMOVE_FILE"){//need INDEX
		let index=action.index;
		state.files.splice(index,1);
		state.total=state.files.length;


	}else if(action.type==="SHIFT_FILE"){//need INDEX, DIRECTION
		let index=action.index;
		if(action.direction=="up"&&index>0){
			let temp=state.files[index-1];
			state.files[index-1]=state.files[index];
			state.files[index]=temp;
		}else if(action.direction=="down"&&index<state.total-1){
			let temp=state.files[index+1];
			state.files[index+1]=state.files[index];
			state.files[index]=temp;
		}
	}else if(action.type==="UPLOAD_STATUS"){//need FILE
		state.uploading=action.status;
	}

	return state;
}
  
export default Reducer;