import React,{Component} from 'react';
//import $ from 'jquery';

export default class AccountMenu extends Component{

	render(){

		// function account(username){
		// 	console.log('clicked my page');
		// 	window.location='/'+username;
		// }

		// function logout(){
		// 	$.post({
		// 		url:'/account/logout',
		// 		success:function(data){
		// 			if(data==1)
		// 				window.location='/';
		// 		}
		// 	});
		// }
	
		// function settings(){
		// 	window.location='/settings';
		// }

		let username=this.props.username;
		return (<div id='popupContainer' className='popupOptions'>

			<div className='popupOption' >
				<div>{username}</div>
			</div>
			{/* <div onClick={function(){account(username);> */}
			<button className='popupOption button' onClick={function(){document.location="/user/"+username;}}>
				<i className="fas fa-user"></i>
				<span>MY PAGE</span>
			</button>

			<button className='popupOption button' onClick={function(){document.location="/account/settings";}}>
				<i className="fas fa-sliders-h"></i>
				<span>SETTINGS</span>
			</button>
			
			<button className='popupOption button' onClick={function(){document.location="/account/logout";}}>
				<i className="fas fa-sign-out-alt"></i>
				<span>LOGOUT</span>
			</button>

			</div>);
	}

}