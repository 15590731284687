import User from "../app/user";

const initState = {
	user: new User(),
}
  
const Reducer = (state=initState,action) => {
	return state;
}
  
export default Reducer;