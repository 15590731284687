import $ from 'jquery';

export default class User{
	constructor(){
		this.id=null;
		this.username=null;
		this.column_count=4;
		this.column_count_mobile=2;
		this.dark_mode='';
		this.finished=false;
		this.getUser();
	}
	//Regex here
	getUser(email='checksession',password=null){
		this.getData(email,password);
	}
	getData(email,password){
		let user=this;
		//console.log('UserObj: Getting user');
		$.ajax({
			url:'/account/login',
			data:{email:email,password:password},
			method:'POST',
			success:function(data){
				if(data!=0){
					user.setUser(JSON.parse(data));
					if(typeof gtag == "function"){//If the page doesn't have the JS snippet then don't try to call the function
						gtag("set",{'user_id':data.id});
					}
				}
			},
			complete:function(){
				user.finished=true;
			}
		});
		
	}
	setUser(data){
		this.id=data.id;
		this.username=data.username;
		this.column_count=parseInt(data.column_count);
		this.column_count_mobile=parseInt(data.column_count_mobile);
		if(data.dark_mode==0)
			this.dark_mode='light';
	}
	isFinished(){
		return this.finished;
	}
}
